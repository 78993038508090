import * as React from 'react';
import { DefaultPalette, Stack } from '@fluentui/react';



// Styles definition

const itemStylesFooter1 = {
    /* marginRight: 20, */
    alignItems: 'center',
    //color: '#696969',
    color: '#1A1A1A',
    display: 'flex',
    height: 25,
    justifyContent: 'center',
    fontSize: 18,
    fontWeight: 400

};

const itemStylesFooter2 = {
    /* marginRight: 20, */
    alignItems: 'center',
    //color: '#696969',
    color: '#1A1A1A',
    display: 'flex',
    height: 50,
    justifyContent: 'center',
    fontSize: 14,
    fontWeight: 400

};

const mainStylesFooter = {
    /* marginRight: 20, */
    alignItems: 'center',
    color: '#1A1A1A',
    display: 'flex',
    height: 50,
    justifyContent: 'center',
    fontSize: 18,
    fontWeight: 500

};

const itemStyles1 = {
    /* marginRight: 20, */
    alignItems: 'center',
    // color: DefaultPalette.white,
    display: 'flex',
    justifyContent: 'center',
    height: 20,
    marginTop: 10,
    fontSize: '14px'
};

const itemStyles2 = {
    /* marginRight: 20, */
    alignItems: 'center',
    // color: DefaultPalette.white,
    display: 'flex',
    justifyContent: 'center',
    height: 20,
    marginTop: 10,
    marginBottom: 10,
    fontSize: '14px'
};

const itemLinkLabel = {
    //color: '#fff',
    textDecoration: 'none',
    fontSize: '14px'
};

const itemLink = {
    //color: '#0078D4',
    textDecoration: 'none',
};

// Tokens definition
const stackTokens = { childrenGap: 5 };

// Tokens definition
/* 
const containerStackTokens = { childrenGap: 5 };
const horizontalGapStackTokens = {
    childrenGap: 10,
    padding: 10,
};
const itemAlignmentsStackTokens = {
    childrenGap: 5,
    padding: 10,
};
 */
const stackStyles = {
    root: {

    },
};
const stackItemStyles = {
    root: {
        alignItems: 'center',
        display: 'flex',
        justifyContent: 'center',
        paddingTop: 15,
        /* paddingBottom: 25, */
        //backgroundColor: 'rgba(241, 241, 241,1)'
        //backgroundColor: 'rgba(0, 120, 212,.05)'
        //backgroundColor: '#F2F6FF'
        
    },
};

const mainLabel = {
    color: '#0078D4'
}

const secontLabel = {
    color: 'rgba(0, 120, 212,.8)'
}

const topFooter = {
    paddingTop: 40,
    paddingBottom: 40,
    backgroundColor: 'rgba(0, 120, 212,.1)'
}




const MobileFooter = () => {
    return (
        
        <Stack>

            <Stack className="footer">
                
                <Stack vertical>
                    <Stack vertical horizontalAlign="center" style={{marginTop: "25px", marginBottom: "10px"}}>
                        {/* <span style={mainStylesFooter}>🧾<a href="https://www.leceipt.com" target="_blank" style={{textDecoration: "none"}}>ซอฟต์แวร์ Leceipt สำหรับออกใบกำกับภาษีอิเล็กทรอนิกส์</a></span> */}
                        <img src="images/logo512.png" alt="ซอฟต์แวร์ Leceipt สำหรับออกใบกำกับภาษีอิเล็กทรอนิกส์" style={{ width: "50px" }}/> <a href="https://www.leceipt.com" target="_blank" rel="noreferrer" style={{textDecoration: "none"}}><span style={mainStylesFooter}>ซอฟต์แวร์ Leceipt</span></a>
                        <a href="https://www.leceipt.com" target="_blank" rel="noreferrer" style={{textDecoration: "none", marginTop: "-10px"}}><span style={mainStylesFooter}>สำหรับออกใบกำกับภาษีอิเล็กทรอนิกส์</span></a>
                        {/* <span style={mainStylesFooter}>พัฒนาโดย</span> */}
                        {/* <span style={itemStylesFooter1}>บริษัท ฟรีเวชั่น จำกัด</span>
                        <span style={itemStylesFooter2}>682 หมู่ 1 ถ.สุขาภิบาล 1 ต.บรบือ อ.บรบือ จ.มหาสารคาม 44130</span> */}
                        {/* <br/> */}

                    </Stack>

                    <Stack horizontal horizontalAlign="center" style={{marginBottom: "10px"}}>
                        <a href="https://www.facebook.com/LeceiptOfficial" target="_blank" rel="nofollow noreferrer"><img src="images/facebook_icon.png" alt="ติดตาม Facebook" style={{ height: "47px", marginRight: "25px"}}/></a>
                        <a href="https://www.youtube.com/@frevation" target="_blank" rel="nofollow noreferrer"><img src="images/youtube-icon.png" alt="เข้าชม YouTube" style={{ height: "47px", marginRight: "25px"}}/></a>
                        <a href="https://line.me/R/ti/p/%40455ixoox" target="_blank" rel="nofollow noreferrer"><img src="images/line-add-friend.png" alt="Add line leceipt" style={{ width: "150px"}}/></a>
                    </Stack>

                </Stack>



            </Stack>

            <br/>
            <Stack tokens={stackTokens} className="copyRight">
                <Stack vertical horizontalAlign="center" styles={stackStyles}>
                    <span style={itemStyles1}><a style={itemLinkLabel} href="https://www.frevation.com/" target="_blank" rel="noreferrer">สงวนลิขสิทธิ์ &copy; บริษัท ฟรีเวชั่น จำกัด</a></span>
                    <span style={itemStyles1}>682 หมู่ 1 ถ.สุขาภิบาล 1 ต.บรบือ อ.บรบือ จ.มหาสารคาม 44130</span>
                    <span style={itemStyles2}>โทร.082-579-8555</span>

                    {/* 
                    <span style={itemStyles}><a style={itemLink} href="https://www.leceipt.com/terms-conditions"> | ข้อกำหนดการใช้งาน | นโยบายบายความเป็นส่วนตัว | นโยบายคืนเงิน | นโยบายยกเลิก | </a></span>
                    <span style={itemStyles}><a style={itemLink} href="https://www.leceipt.com/privacy-policy">Privacy Policy</a></span>
                     */}
                </Stack>
            </Stack>
        
       </Stack>
    )
}

export default MobileFooter