import * as React from 'react';
import { DefaultPalette, Stack } from '@fluentui/react';
import PropTypes from 'prop-types'

//Google Analytics
import ReactGA from 'react-ga';
ReactGA.initialize('UA-204665673-1');
ReactGA.pageview(window.location.pathname + window.location.search);

// Styles definition
const stackStyles = {
    root: {
        //background: DefaultPalette.themePrimary,
        background: 'white',
        height: 50,
        //color: DefaultPalette.white,  
        //color: 'gray',
        color: '#1A1A1A',
        borderBottom: '1.5px solid #E0E0E0',
        //width: '100vw'
        
        

            
    },
};
/* 
const itemStyles = {
    //alignItems: 'center',
    color: DefaultPalette.white,    
    //display: 'flex',
    height: 80,
    //justifyContent: 'center',

};
 */
// Tokens definition
//const stackTokens = { childrenGap: 5 };

const MobileHeader = ({ title }) => {
    return (            
        <header className="mobile-header">
            <Stack horizontal styles={stackStyles} horizontalAlign="center" wrap={false}>
                

                    <Stack vertical verticalAlign="center" style={{ width: '10', paddingLeft: '5px' }}>
                        <Stack horizontal horizontalAlign="center" >
                            
                            <a href="/"><img src="images/leceipt-check-logo.png" style={{ height: "40px", marginTop: "5px" }} alt="ระบบตรวจสอบใบกำกับภาษีอิเล็กทรอนิกส์ (e-Tax Invoice & e-Receipt)" /></a>
                        </Stack>
                    </Stack>
                    
                    {/* 
                    <Stack vertical verticalAlign="center" style={{ width: '80', marginLeft: '0px' }}>
                        

                        <Stack horizontal horizontalAlign="center" >
                            <span style={{ fontSize: "16px" }}>{title}</span>
                        </Stack>
                        
                    </Stack>  
                    <Stack vertical verticalAlign="center" style={{ paddingLeft: '5px' }}>
                        <Stack horizontal horizontalAlign="start" >
                            
                        </Stack>
                    </Stack>                  
                    
                */}
            </Stack>
        </header>    


        
    )
}

MobileHeader.defaultProps = {
    title: 'ระบบตรวจสอบเอกสาร e-Tax Invoice & e-Reciept'
}

MobileHeader.propTypes = {
    title: PropTypes.string
}

export default MobileHeader